"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.loginAction = exports.onLoginError = exports.onLoginSuccess = void 0;
var _axios = _interopRequireDefault(require("axios"));
var _lodash = require("lodash");
var _conf = _interopRequireDefault(require("@tvg/conf"));
var _protocolSetter = _interopRequireDefault(require("@tvg/api/protocolSetter"));
var _helper = require("../services/helper");
var _geocomply = _interopRequireDefault(require("../services/middleware/geocomply"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const onLoginSuccess = response => ({
  success: {
    status: "success",
    data: response.data
  },
  error: null
});
exports.onLoginSuccess = onLoginSuccess;
const onLoginError = error => {
  if (!error.response || !error.response.data || error.response.status >= 500) {
    return {
      error: {
        status: "error",
        message: "An error occurred and we could not log you in at this time. Please <a target='_blank' href='/redirectengine?type=messageus'>contact our customer service</a> for assistance."
      },
      success: null
    };
  }
  const redirect = error.response.data.redirectUrl ? {
    redirectUrl: error.response.data.redirectUrl
  } : {};
  const responsibleGamingExclusion = error.response.data.responsibleGamingExclusion ? {
    responsibleGamingExclusion: error.response.data.responsibleGamingExclusion
  } : {};
  const remainingTries = {
    remainingTries: error.response.data.remainingTries
  };
  return {
    error: {
      status: "error",
      ...{
        message: (0, _helper.errorParser)(error.response.data.exception)
      },
      ...redirect,
      ...responsibleGamingExclusion,
      ...remainingTries
    },
    success: null
  };
};
exports.onLoginError = onLoginError;
const loginRequest = (payload, geo) => (0, _axios.default)({
  method: "post",
  url: `${(0, _protocolSetter.default)()}${(0, _conf.default)().config().service.usa}/login`,
  data: {
    account: payload.account,
    pin: payload.pin,
    stateAbbr: payload.stateAbbr,
    ...geo
  },
  headers: {
    ...(0, _helper.tvgHeaders)()
  },
  withCredentials: true
}).then(onLoginSuccess).catch(onLoginError);
const loginAction = payload => (0, _geocomply.default)(payload, loginRequest, ((0, _conf.default)().device === "desktop" || (0, _conf.default)().product === "tvg4") && (0, _conf.default)().brand === "4njbets");
exports.loginAction = loginAction;