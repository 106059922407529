import {
  GeolocationStatus,
  GeolocationRejectedErrorMessage,
  GeolocationRetryStatus,
  GeolocationRetryState
} from "../types";
import {
  GeoLocationMessageShownAction,
  GeoLocationStatusAction,
  GeoLocationStatusClearAction,
  GeoLocationErrorAction,
  GeoLocationRetryStatusAction,
  TryGeoLocationRetryAction,
  GeoLocationRetryClearAction,
  GeoComplyErrorModalAction
} from "./types";

export const geolocationMessageShown = (
  isMessageShown: boolean
): GeoLocationMessageShownAction => ({
  type: "GEOLOCATION_MESSAGE_SHOWN",
  payload: {
    geolocationMessageShown: isMessageShown
  }
});

export const setGeolocationStatus = (
  status: GeolocationStatus
): GeoLocationStatusAction => ({
  type: "GEOLOCATION_STATUS",
  payload: {
    geolocationStatus: status
  }
});

export const setGeolocationError = (
  errorType: string,
  errorData: GeolocationRejectedErrorMessage[] | string,
  isErrorRetryable: boolean
): GeoLocationErrorAction => ({
  type: "GEOLOCATION_ERROR",
  payload: {
    geolocationError: {
      [errorType]:
        typeof errorData === "string" ? [{ message: errorData }] : errorData
    },
    isErrorRetryable
  }
});

export const geolocationStatusClear = (): GeoLocationStatusClearAction => ({
  type: "GEOLOCATION_STATUS_CLEAR"
});

export const setGeolocationRetryStatus = (
  retryStatus: GeolocationRetryStatus
): GeoLocationRetryStatusAction => ({
  type: "SET_GEOLOCATION_RETRY_STATUS",
  payload: retryStatus
});

export const tryGeolocationRetry = (
  status: boolean
): TryGeoLocationRetryAction => ({
  type: "TRY_GEOLOCATION_RETRY",
  payload: status
    ? GeolocationRetryState.REQUESTED
    : GeolocationRetryState.RETRYING
});

export const geolocationRetryClear = (): GeoLocationRetryClearAction => ({
  type: "GEOLOCATION_RETRY_CLEAR"
});

export const geoComplyErrorModal = (): GeoComplyErrorModalAction => ({
  type: "GEOCOMPLY_ERROR_MODAL"
});
