import React, { useMemo, useState, useEffect, forwardRef } from "react";
import {
  Image as ImageRN,
  ImageErrorEventData,
  LayoutChangeEvent,
  NativeSyntheticEvent,
  Platform
} from "react-native";
import { ImageProps } from "./types";
import { useQaLabel } from "../../hooks/useQaLabel";
import { StyledImage, Container } from "./styled-components";
import { Error } from "./error";

export const Image = forwardRef<ImageRN, ImageProps>(
  (
    {
      source,
      width,
      height,
      isResponsive = false,
      accessibilityLabel = "",
      qaLabel = "image-label",
      resizeMode = "stretch",
      ...rest
    },
    ref
  ) => {
    const viewTestProps = useQaLabel(qaLabel);
    const [containerWidth, setContainerWidth] = useState<number>(0);
    const [sourceWidth, setSourceWidth] = useState<number>(0);
    const [, setSourceHeight] = useState<number>(0);
    const [ratio, setRatio] = useState<number>(0);
    const [error, setError] =
      useState<NativeSyntheticEvent<ImageErrorEventData> | null>(null);

    const setDimension = (w: number, h: number) => {
      setSourceWidth(w);
      setSourceHeight(h);
      setRatio(h / w);
    };

    useEffect(() => {
      if (Platform.OS === "web" || typeof source === "string") {
        ImageRN.getSize(source as string, (w, h) => {
          setDimension(w, h);
        });
      } else {
        const { width: w, height: h } = ImageRN.resolveAssetSource(source);
        setDimension(w, h);
      }
    }, []);

    const onViewLayoutChange = (event: LayoutChangeEvent) => {
      const { width: w } = event.nativeEvent.layout;
      setContainerWidth(w);
    };

    const getWidth = () => {
      if (isResponsive) return containerWidth;
      if (!width && height) return height * ratio;
      if (!width && !height) return sourceWidth;
      return width || 0;
    };

    const imageStyles = useMemo(
      () => ({
        width: getWidth(),
        height: height || getWidth() * ratio
      }),
      [containerWidth, ratio]
    );

    const errorHandler = (err: NativeSyntheticEvent<ImageErrorEventData>) => {
      setError(err);
    };

    if (error) return <Error>{accessibilityLabel}</Error>;

    return (
      <Container onLayout={onViewLayoutChange}>
        <StyledImage
          resizeMode={resizeMode}
          source={typeof source === "string" ? { uri: source } : source}
          style={imageStyles}
          accessible={Platform.OS === "web" ? undefined : true}
          accessibilityLabel={accessibilityLabel}
          {...viewTestProps}
          {...rest}
          // @ts-ignore
          ref={ref}
          onError={errorHandler}
        />
      </Container>
    );
  }
);
