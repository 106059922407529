import React, { FC } from "react";
import { ErrorContainer } from "./styled-components";
import { Icon } from "../icon";
import { Paragraph } from "../typography/paragraph";

export const Error: FC = ({ children }) => (
  <ErrorContainer>
    <Icon name="error" size="s" />
    <Paragraph qaLabel="label-error-image" ml="space-1">
      {children as string}
    </Paragraph>
  </ErrorContainer>
);
