import styled, { css } from "styled-components/native";
import { View, Text, Pressable, Platform, Animated } from "react-native";
import { space, width } from "../../theming/styledSystem";
import { SegmentSize } from "./types";

export const sizesMap = {
  s: {
    height: 32
  },
  m: {
    height: 40
  },
  l: {
    height: 52
  }
};

export const StyledComponent = styled(View)<{
  isDisabled: boolean;
  size: SegmentSize;
}>`
  overflow: hidden;
  position: relative;
  height: ${({ size }) => sizesMap[size]?.height}px;
  border-radius: 68px;
  ${({ theme }) =>
    css`
      background-color: ${theme.colorTokens.background.base};
      padding: ${theme.space["space-1"]};
    `};
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      opacity: 0.4;
    `}
  ${space};
  ${width};
`;

export const SegmentsContainer = styled(View)`
  flex: 1;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  elevation: 5;
  background-color: ${({ theme }) => theme.colorTokens.background.default};
`;

export const SegmentTab = styled(Pressable)<{ isFocused: boolean }>`
  height: 100%;
  flex: 1;
  ${({ isFocused }) =>
    Platform.OS !== "web" &&
    isFocused &&
    css`
      outline-style: solid;
      outline-width: 1px;
    `}
`;

export const SegmentTabContent = styled(Animated.View)`
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 1px;
`;

export const SegmentText = styled(Text)<{ isSelected?: boolean }>`
  ${({ theme, isSelected }) => css`
    font-family: ${theme.fonts.medium};
    font-size: ${theme.fontSizes.s};
    line-height: ${theme.lineHeights.s};
    color: ${isSelected
      ? theme.colorTokens.content.default
      : theme.colorTokens.content.subtle};
  `}
`;
