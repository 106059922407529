import { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { graphql } from "@apollo/client/react/hoc";
import { get } from "lodash";
import useMtpUpdate, { getDateRace } from "./hooks/useMtpUpdate";
// @ts-ignore
import MTPStatusQuery from "./graphql/queries/MTPStatusUpdate.graphql";
import ApolloOptions from "./graphql/options.graph";
import { MtpRaceDate, MtpRacesUpdate } from "./actions";
import Props from "./types";

export const MtpStatusComponent = ({
  mtpRaces,
  raceDate,
  children,
  shouldRunSubscription
}: Props) => {
  const dispatch = useDispatch();
  const storedRaceDate = useSelector(getDateRace);

  useMtpUpdate(shouldRunSubscription);

  useEffect(() => {
    if (raceDate && raceDate !== storedRaceDate) {
      dispatch(MtpRaceDate(raceDate));
    }
  }, [raceDate]);

  useEffect(() => {
    dispatch(MtpRacesUpdate(mtpRaces || []));
  }, [mtpRaces]);

  return children;
};

export default connect(
  (store) => ({
    wagerProfile: get(store, "userData.user.profile", "PORT-Generic"),
    shouldRunSubscription: get(
      store,
      "capi.featureToggles.mtpUpdateSubscriptionMblEnabled",
      false
    )
  }),
  (dispatch) => ({ dispatch })
  // @ts-ignore
)(graphql(MTPStatusQuery, ApolloOptions)(MtpStatusComponent));
