import React from "react";
import { graphql } from "@apollo/client/react/hoc";
import TracksMask from "@tvg/atomic-ui/_static/Masks/tracks";
import RaceTracksEmptySearch from "@tvg/atomic-ui/_molecule/RaceTracksEmptySearch";
import { Device } from "@tvg/ts-types/Device";
import RaceTracksSection from "../RaceTracksSection";
import FullTrackList from "../FullTrackList";
// @ts-ignore
import TrackListQuery from "../../../../graphql/queries/TracksListSeo.graphql";
import ApolloOptionsTL from "../../../../graphql/options.graph";
import TrackRow from "../TrackRow";
import { TracksListChild as Props } from "../../../../types";
import { getCountryFullName } from "../../../../utils/trackInfo";

export const TracksListChild = (props: Props) => {
  const renderContent = () => {
    if (props.sections.length) {
      return props.sections.map((section) => {
        const groupTracks = Object.entries(section.data);
        return (
          groupTracks.length > 0 && (
            <RaceTracksSection
              key={section.title}
              title={section.title}
              isGroupSection
            >
              {groupTracks.map(([acronymState, tracksFromGroup]) => {
                const title =
                  section.type === "usa" || !acronymState
                    ? acronymState || "Other"
                    : getCountryFullName(acronymState);
                return (
                  <RaceTracksSection key={acronymState} title={title}>
                    {tracksFromGroup.map((track) => (
                      <TrackRow
                        key={`track-${track.code}`}
                        track={track}
                        favoriteId={props.tracksWithId[track.code] || null}
                        onTrackClick={props.onTrackClick}
                        device={props.device}
                        isLogged={props.isLogged}
                        accountNumber={props.accountNumber}
                        isAccountCompliant={props.isAccountCompliant}
                      />
                    ))}
                  </RaceTracksSection>
                );
              })}
            </RaceTracksSection>
          )
        );
      });
    }

    return (
      <RaceTracksEmptySearch
        hasCenterAlignment={props.device === Device.DESKTOP}
      />
    );
  };

  return (
    <FullTrackList
      renderHeader={!props.isLoading}
      onBackClick={props.onBackClick}
      isDesktop={props.device === Device.DESKTOP}
      isLoading={props.isLoading}
      onCancelSearch={props.onCancelSearch}
      onBlurSearch={props.onBlurSearch}
      onFocusSearch={props.onFocusSearch}
      searchQuery={props.searchQuery}
      setSearchQuery={props.setSearchQuery}
      showPageHeader={props.showPageHeader}
      showCancelSearch={props.showCancelSearch}
      searchInputRef={props.searchInputRef}
      enableSeoRaceTracksSearch={props.enableSeoRaceTracksSearch}
    >
      {props.isLoading ? <TracksMask /> : renderContent()}
    </FullTrackList>
  );
};

// @ts-ignore
export default graphql(TrackListQuery, ApolloOptionsTL)(TracksListChild);
