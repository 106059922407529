import { IconNames, ToastMessageProps } from "@tvg/design-system";
import { VisualSelections } from "@tvg/ts-types/Bet";
import { SpecialGroupBets } from "@tvg/desktop-bet/src/types";
import { Probable, Timeform } from "@tvg/ts-types/Race";

export enum BetError {
  geoComplyTokenRejected = 136524,
  geoComplyTokenMissing = 136463
}

export type Race = {
  id: string;
  raceNumber: string;
  mtp: string;
  track: {
    trackName: string;
    trackLocation: {
      country: string;
    };
  };
  status: { code: string };
};

export type Status = "SK" | "RO" | "O" | "IC";

export type ProgramPageExternalRequirements = {
  track: string;
  race: string;
  wagerProfile: string;
  accountNumber: string;
  channel: string;
};

type BaseProgram = unknown;

export type ProgramPageProps = ProgramPageExternalRequirements & {
  isLoading: boolean;
  races: BaseProgram;
};

export type FavoriteWithIdTracks = {
  [code: string]: number;
};

export type Favorite = {
  entityDob?: number | null;
  entityName: string;
  entityType: string;
  favoriteId: number;
  subscriptions?: FavoriteSubscriptions[];
};

export type FavoriteSubscriptions = {
  eventType?: string;
  channels?: ChannelsSubscriptions[];
};

export type ChannelsSubscriptions = {
  type?: string;
};

export type RaceType = {
  code?: string;
  name?: string;
  shortName?: string;
};

export type RaceSurface = {
  defaultCondition?: string;
  name?: string;
  shortName?: string;
};

export type RaceClass = {
  shortName?: string;
};

export interface BlacklistedWagerTypes {
  blacklistedBets: string[];
}

export type Selection = {
  number: string;
  numberColor: string;
  saddleColor: string;
};

export type Selections = Array<Selection>;

export type PreferencesType = {
  [key: string]: string | object;
};

export enum CONFIRM_MODAL_PREFERENCE {
  ALWAYS = "1",
  NEVER = "0"
}

export type PreferencesPayload = {
  preferences: PreferencesType;
  gotPreferences: boolean;
};
export type SaddleType = "thoroughbred" | "harness";

export type PlaceBetErrorMessage = {
  title: string;
  text: string;
  buttonType: string;
};

export type PlaceBetError = {
  code: number;
  message: PlaceBetErrorMessage;
};

export type WtxPlaceBetResponse = {
  summary: {
    userBalance: number;
  };
  status: [
    {
      success: {
        serialNumber: string;
      };
    }
  ];
};

export type WtxPlaceBetError = {
  response: {
    data: {
      code: number;
      message: string;
    };
  };
};

export type SavedBets = {
  betAmount: number;
  betNumber: number;
  totalAmount: string;
  approxPayout: string;
  wagerableRunners: string[] | undefined;
  betProbables: Probable[];
  fullSelections: VisualSelections[];
  specialGroupBets: SpecialGroupBets | undefined;
  betSelectionsNumbers: string[][];
};

export interface EmptyMessage {
  title: string;
  description: string;
}

export interface WillPay {
  title: string;
  description: string;
}

export interface InlinePastPerformanceResultRunners {
  finishPosition: number;
  currentOdds: {
    numerator?: number;
    denominator?: number;
  };
  timeform: Timeform | null;
  favorite: boolean;
}

export interface InlinePastPerformance {
  id: number;
  date: string;
  purse: number;
  track: {
    code: string;
  };
  surface: {
    shortName: string;
    defaultCondition: string;
  };
  distance: {
    code: string;
    value: number;
  };
  raceClass: {
    shortName: string;
  };
  video?: {
    replayFileName: string;
    mobileAvailable: boolean;
    flashAvailable: boolean;
  };
  results: {
    runners: InlinePastPerformanceResultRunners[];
  };
  numRunners: string;
}

export enum RunnerModifierEnum {
  DEFAULT = "default",
  ADVANCED = "advanced",
  PERFORMANCE = "performance"
}

export enum PickViewOptionsEnum {
  QUICK_PICK = "quickPick",
  HANDICAPPING = "handicapping"
}

export enum TabSelectionEnum {
  RESULT = "result",
  RACECARD = "racecard"
}

export enum TabSelectionPoolsNInfoEnum {
  INFO_CHANGES = "infoChanges",
  PROBABLES = "probables",
  POOLS = "pools",
  WILL_PAYS = "willPays"
}

export enum FiltersInlinePP {
  RECENTRACE = "recentRace",
  CURRENTRACE = "currentRace"
}
export interface InlinePPEmptyMessages {
  recentRaces: string;
  filteredRaces: string;
}

export interface InlinePPMessage {
  maxResults: number;
  emptyMessages: InlinePPEmptyMessages;
}

export enum RunnerSortOrderEnum {
  ASC = "asc",
  DESC = "desc"
}

export enum RunnerSortByEnum {
  SADDLECLOTH = "biNumber",
  ODDS = "currentOddNumeric",
  STARTS = "numberOfStarts",
  POWER_RATING = "powerRating",
  WEIGHT = "weight",
  WIN_PERCENTAGE = "winPercentage",
  TOP3_PERCENTAGE = "top3WinPercentage",
  JT_STARTS = "jockeyTrainerStarts",
  JT_WIN_PERCENTAGE = "jockeyTrainerWinPercentage",
  JT_TOP3_PERCENTAGE = "jockeyTrainerTop3Percentage",
  AVG_SPEED = "avgSpeed",
  HIGH_SPEED = "highSpeed",
  AVG_CLASS_RATING = "avgClass",
  EARLY_PACE = "averagePaceEarly",
  MIDDLE_PACE = "averagePaceMiddle",
  FINISH_PACE = "averagePaceFinish"
}

export type SortOption = {
  label: string;
  value: RunnerSortByEnum;
};

export type HandicappingTrackDataSource =
  | "USGSA"
  | "GSA"
  | "SRW"
  | "RAS"
  | "EQB";

export type HandicappingTabValue = "timeform" | "handicapping" | "talentPick";

export interface HandicappingTab {
  name: string;
  icon: Extract<
    IconNames,
    "timeForm" | "numberFire" | "badge" | "trackMaster" | "rs" | "tvgPicks"
  >;
  value: HandicappingTabValue;
}

export type HandicappingTabsMap = Record<
  HandicappingTrackDataSource,
  HandicappingTab[]
>;

export interface HandicappingModuleMessage {
  title?: string;
  tabs?: HandicappingTabsMap;
}

export interface RaceWagerSummary {
  totalBets: number;
  totalAmount: number;
  totalGambledCount: number;
  totalGambledAmount: number;
}

export interface RepeatBetConfig {
  maxAllowed: number;
  errorMessage: {
    title: string;
    text: string;
  };
}

export type handicappingRunnerFlagsName = {
  flags: {
    id: string;
    name: string;
  }[];
};
export interface TalentPickBet {
  existingModalBet: {
    title: string;
    text: string;
  };
}

export interface ForbiddentBettingStates {
  blockedStates: [string] | [];
  errorMessage: string;
}

export type ToastMessageStore = ToastMessageProps & { id: string };
