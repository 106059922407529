import React from "react";
// @ts-ignore
import { AppRegistry } from "react-native-web";
import { ApolloProvider } from "@apollo/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import tvgConf from "@tvg/conf";
import ApolloContext from "@tvg/utils/apolloContext";
import { TVGThemeProviderWeb } from "@tvg/design-system";
import RaceTracks from "@tvg/gtm/src/modules/RaceTracks";
import ApolloClient from "./apolloClient/ApolloClient";
import configureStore from "./store/configureStore";
import Main from "./pages";

const preloadedState = window.__RACETRACKS_PRELOADED_STATE__; // eslint-disable-line no-underscore-dangle

// GTM subscribers event init
RaceTracks();

const store = configureStore(preloadedState);
// gets conf for specific host / device
tvgConf(window.location.hostname, window.navigator.userAgent);

const App = () => (
  <TVGThemeProviderWeb isFanduelTheme={false}>
    <ApolloProvider client={ApolloClient.createClient(false)}>
      <ApolloContext.Provider
        value={{
          fcpClient: ApolloClient.createClient(false, "fcp"),
          rdaClient: ApolloClient.createClient(false, "rda")
        }}
      >
        <Provider store={store}>
          <BrowserRouter>
            <Main />
          </BrowserRouter>
        </Provider>
      </ApolloContext.Provider>
    </ApolloProvider>
  </TVGThemeProviderWeb>
);

const Render = () => {
  if (document.getElementById("racetracks")) {
    AppRegistry.registerComponent("App", () => App);

    AppRegistry.runApplication("App", {
      initialProps: {},
      rootTag: document.getElementById("racetracks")
    });
  }
};

export default Render();
